<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-head">
        Privacy policy
      </div>

      <div class="common-content-row">
        Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose,
        and protect your information when you visit our website,
        {{ domainConfig["IAmURL"] }}. Please read this policy carefully. If you do not
        agree with the terms of this Privacy Policy, please do not access the site.
      </div>

      <div class="common-content-head">
        <strong>Information We Collect</strong>
      </div>

      <div class="common-content-row">
        We may collect various types of information about you when you visit our website. The types
        of information we collect include:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>Personal Data</strong>: This includes personally identifiable information such
            as your name, email address, and any other details you voluntarily provide when you
            register on the site or engage in activities such as commenting or submitting feedback.
          </li>
          <li>
            <strong>Derivative</strong> <strong>Data</strong>: Information that our servers
            automatically collect when you access the site, such as your IP address, browser type,
            operating system, access times, and the pages you viewed before and after accessing the
            site.
          </li>
          <li>
            <strong>Mobile Device Data</strong>: If you access the site via a mobile device, we
            may collect information about your device, including the device ID, model, manufacturer,
            and location data.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Use of Your Information</strong>
      </div>

      <div class="common-content-row">
        The information we collect about you helps us provide a smoother, more personalized
        experience on our site. Specifically, we may use your information for the following
        purposes:
      </div>

      <div class="common-content-row">
        <ul>
          <li>To create and manage your account.</li>
          <li>
            To send you technical notices, updates, security alerts, and administrative messages.
          </li>
          <li>To respond to your inquiries and provide customer support.</li>
          <li>To monitor and analyze usage trends and activities to improve our services.</li>
          <li>
            To personalize the site and deliver relevant advertisements, content, or features that
            align with your interests.
          </li>
          <li>
            To facilitate contests, sweepstakes, and promotions, and to process entries and
            rewards.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Disclosure of Your Information</strong>
      </div>

      <div class="common-content-row">
        We may disclose your information in the following situations:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>By Law or to Protect Rights</strong>: We may share your information if
            required by law, to respond to legal processes, to investigate potential violations of
            our policies, or to protect the rights, property, and safety of others.
          </li>
          <li>
            <strong>Business Transfers</strong>: If we undergo a merger, acquisition, or sale of
            assets, your information may be transferred to the new owner.
          </li>
          <li>
            <strong>Third-Party Service Providers</strong>: We may share your information with
            third-party service providers who assist us with tasks such as data analysis, email
            delivery, hosting services, customer service, and marketing.
          </li>
          <li>
            <strong>Marketing Communications</strong>: With your consent, we may share your
            information with third parties for marketing purposes, as permitted by law. You may opt
            out at any time.
          </li>
          <li>
            <strong>Online Postings</strong>: Any content, comments, or contributions you post on
            the site may be viewed by other users and may be publicly distributed outside the site.
          </li>
          <li>
            <strong>Third-Party Advertisers</strong>: We may use third-party advertising companies
            to display ads when you visit the site. These companies may use cookies to gather
            information about your visits to the site and other websites to serve advertisements
            about products and services that may interest you.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <strong>Security of Your Information</strong>
      </div>

      <div class="common-content-row">
        We implement administrative, technical, and physical security measures to protect your
        personal information. While we take reasonable steps to secure your data, please be aware
        that no security measures are entirely foolproof, and no method of data transmission is
        completely secure.
      </div>

      <div class="common-content-head">
        <strong>Policy for Children</strong>
      </div>

      <div class="common-content-row">
        We do not knowingly collect information from children under the age of 13. If we learn that
        we have collected personal information from a child under 13 without proper parental
        consent, we will delete that information promptly. If you believe we may have collected such
        information, please contact us.
      </div>

      <div class="common-content-head">
        <strong>Changes to This Privacy Policy</strong>
      </div>

      <div class="common-content-row">
        We may update this Privacy Policy periodically to reflect changes in our practices or for
        legal or regulatory reasons. We will notify you of any updates by posting the new Privacy
        Policy on our site. Please review this policy regularly for any changes.
      </div>

      <div class="common-content-head">
        <strong>Contact Us</strong>
      </div>

      <div class="common-content-row">
        If you have any questions or comments about this Privacy Policy, please contact us at:
      </div>

      <div class="common-content-row">
        Email: {{ domainConfig['IAMEMAIL'] }}
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Privacy policy | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Review the Privacy Policy for ${this.domainConfig["IAmURL"]}. Learn how we handle user-uploaded content and data collected through Firebase and advertising services.`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>